import React from "react";
import {Box, Container, CssBaseline} from "@mui/material";

interface Props {
    mt?: string,
    children?: any
}

const PageWrapper = ({mt ,children}: Props) => (
    <div>
        <CssBaseline/>
        <Container maxWidth="lg" sx={{mt: mt ? mt : '6rem'}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
            }}>
                {children}
            </Box>
        </Container>
    </div>
);

export default PageWrapper;
