import React, {useEffect} from 'react';

import {createTheme, ThemeProvider} from '@mui/material/styles';
import {CssBaseline} from "@mui/material";
import AppRoutes from "./AppRoutes";
import {useDispatch} from "react-redux";
import {useAppSelector} from "./app/hooks";
import {loadMeetingsAsync} from "./features/meetings/meetingSlice";
import {STATUS} from "./http-client/http-client";

const theme = createTheme({
  typography: {
    fontFamily: [
      'Open Sans Condensed',
      'sans-serif'
    ].join(',')
  },
});
function App() {
  const dispatch = useDispatch();
  const { meetings, status } = useAppSelector(state => state.meetings);

  useEffect(() => {

    if (meetings.length === 0) {
      // @ts-ignore
      dispatch(loadMeetingsAsync())
    }

  }, [meetings]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <AppRoutes/>
    </ThemeProvider>
  );
}

export default App;
