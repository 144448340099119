import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {CircularProgressPropsColorOverrides} from "@mui/material/CircularProgress/CircularProgress";

interface Props {
    open: boolean
    message?: string
    color?: OverridableStringUnion<
        'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit',
        CircularProgressPropsColorOverrides
        >
}
const BackDrop = ({open, message, color }:Props) => {

    return (
        <div>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={open}
            >
                <Stack spacing={3}>
                    <CircularProgress color={color ? color : "inherit"}/>
                    <Typography sx={{textAlign: "center"}}>
                        {message}
                    </Typography>
                </Stack>


            </Backdrop>
        </div>
    );
}

export default BackDrop;
