import {getRequest} from "../../http-client/http-client";

export async function loadMeetings() {
    return getRequest("/meetings", undefined)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            }
            throw resp;
        });
}

export async function loadMeeting(pid: string) {
    return getRequest(`/meetings/${pid}`, undefined)
        .then(resp => {
            if (resp.ok) {
                return resp.json();
            }
            throw resp;
        });
}
