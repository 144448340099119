import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import AbsencePage from "./pages/AbsencePage";
import SandboxPage from "./pages/SandboxPage";

const AppRoutes = () => {
    return (
        <>
            <Routes>
                <Route path={'/'} element={<AbsencePage/>}/>
                <Route path={'/sandbox'} element={<SandboxPage/>}/>
                <Route path={"*"} element={<Navigate to={'/'}/>}/>
            </Routes>
        </>
    )
}

export default AppRoutes;
