const APPLICATION_JSON = 'application/json';

export function httpRequest(body: any, endpoint: string, token: string | undefined, method: string, headers = {}) {
    // @ts-ignore
    const baseUrl = window.API_URL;

    let updatedHeaders: HeadersInit = {
        ...headers,
        'Content-Type': APPLICATION_JSON
    }

    if (token) {
        updatedHeaders = {
            ...updatedHeaders,
            Authorization: `Bearer ${token}`,
        }
    }

    let options: RequestInit = {
        method: method,
        headers: updatedHeaders
    }

    if (body) {
        options = {
            ...options,
            body: body
        }
    }
    return fetch(`${baseUrl}${endpoint}`, options);
}

export function formDataRequest(formData: any, endpoint: string, token: string, headers = {}) {
    // @ts-ignore
    const baseUrl = window.API_URL;

    let updatedHeaders = {
        ...headers,
        Authorization: `Bearer ${token}`
    }

    let options = {
        method: 'POST',
        headers: updatedHeaders,
        body: formData
    }
    return fetch(`${baseUrl}${endpoint}`, options);
}

export function getRequest(endpoint: string, token: string | undefined, headers = {}) {
    return httpRequest(null, endpoint, token, 'GET', headers);
}

export function postRequest(endpoint: string, token: string | undefined, body: any, headers = {}) {
    return httpRequest(body, endpoint, token, 'POST', headers);
}

export function putRequest(endpoint: string, token: string | undefined, body: any, headers = {}) {
    return httpRequest(body, endpoint, token, 'PUT', headers);
}

export function deleteRequest(endpoint: string, token: string, body: any, headers: Map<string, string>) {
    return httpRequest(body, endpoint, token, 'DELETE', headers);
}

export enum STATUS {
    LOADING = 'loading',
    SUCCESS = 'success',
    FAILED = 'failed',
    IDLE = 'idle'
}
