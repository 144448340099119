import {STATUS} from "../../http-client/http-client";
import {Meeting} from "../../lib/types/meeting";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {loadMeeting, loadMeetings} from "./meetingAPI";

export interface MeetingState {
    meetings: Array<Meeting>,
    status: STATUS
}


const initialState: MeetingState = {
    meetings: [],
    status: STATUS.IDLE
}
export const loadMeetingsAsync = createAsyncThunk(
    "meeting/loadMeetings",
    async () => {
        return loadMeetings();
    }
)

export const loadMeetingAsync = createAsyncThunk(
    "meeting/loadMeeting",
    async (pid: string) => {
        return loadMeeting(pid)
    }
)

export const meetingSlice = createSlice({
    name: "meeting",
    initialState,
    reducers: {
        setMeetings: (state, action) => {
            state.meetings = action.payload;
            state.status = STATUS.SUCCESS
        },
        clearMeetings: (state) => {
            state.meetings = initialState.meetings;
            state.status = STATUS.IDLE
        }
    },
    extraReducers: builder =>
        builder
            .addCase(loadMeetingsAsync.pending, (state) => {
                state.status = STATUS.LOADING
            })
            .addCase(loadMeetingsAsync.rejected, state => {
                state.status = STATUS.FAILED
            })
            .addCase(loadMeetingsAsync.fulfilled, (state, action) => {
                state.meetings = action.payload;
                state.status = STATUS.SUCCESS;
            })
});

export const { setMeetings, clearMeetings } = meetingSlice.actions;
export default meetingSlice.reducer;
