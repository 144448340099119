import React from "react";
import {Stack, useMediaQuery, useTheme} from "@mui/material";

const ResponsiveStack = ({ children, spacing }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Stack direction={ matches ? "row" : "column"} spacing={spacing && spacing > 0 ? spacing : 2}>
            {children}
        </Stack>
    )
};

export default ResponsiveStack;
