import React from "react";
import PageWrapper from "../wrappers/PageWrapper";
import AbsenceSuccess from "../components/absence/absence-success";

const SandboxPage = () =>  {
    return (
        <PageWrapper>
            <AbsenceSuccess/>
        </PageWrapper>
    )
}
export default SandboxPage;
