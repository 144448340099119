import React from 'react';
import Typography from "@mui/material/Typography";
import {CheckCircle} from "@mui/icons-material";

const AbsenceSuccess = () => {
    return (
        <>
            <Typography variant={"h4"} component={"div"} color={"primary"}>
                Your absence has been registered <CheckCircle/> 🎉
            </Typography>
        </>
    )
}

export default AbsenceSuccess;
