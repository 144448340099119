import React, {useState} from "react";
import {Alert, Box, Button, Paper, TextField, useMediaQuery, useTheme} from "@mui/material";
import {AppRegistration} from "@mui/icons-material";
import PageWrapper from "../wrappers/PageWrapper";
import {MarginBottomDiv} from "../utils/margin-div";
import Typography from "@mui/material/Typography";
import {STATUS} from "../http-client/http-client";
import BackDrop from "../components/backdrop/back-drop";
import {useNavigate} from "react-router-dom";
import {MuiTelInput} from "mui-tel-input";
import MeetingSelector from "../components/select/meeting-selector";
import AbsenceSuccess from "../components/absence/absence-success";
import ResponsiveStack from "../utils/responsive-stack";
import {RegisterAbsenceRequest} from "../lib/types/absence";
import {Meeting} from "../lib/types/meeting";
import {registerAbsence} from "../features/absence/absenceAPI";

const AbsencePage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));


    const [fullName, setFullName] = useState('');
    const [fullNameError, setFullNameError] = useState(false);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);

    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState(false);

    const [meeting, setMeeting] = useState(Array<Meeting>);
    const [meetingError, setMeetingError] = useState(false);


    const [registerStatus, setRegisterStatus] = useState(STATUS.IDLE);
    const [registerError, setRegisterError] = useState('could not register your absence, please try again later');


    const reset = () => {
        window.location.reload();
    }

    const clearErrors = () => {
        setFullNameError(false);
        setEmailError(false);
        setPhoneError(false);
        setReasonError(false);
        setRegisterStatus(STATUS.IDLE);
    }

    const handleEmailChange = (event: any) => {
        setEmailError(false);
        setEmail(event.target.value);
    }

    const handlePhoneChange = (phone: any) => {
        setPhoneError(false);
        setPhone(phone);
    }

    const handleReasonChange = (event: any) => {
        setReasonError(false);
        setReason(event.target.value);
    }

    const handleFullNameChange = (event: any) => {
        setFullNameError(false);
        setFullName(event.target.value);
    }

    const handleMeetingChange = (meetings: Array<Meeting>) => {
        setMeetingError(false);
        setMeeting(meetings);
    }

    const registerUserAbsence = () => {
        clearErrors();
        let errors = false;

        if (fullName === '') {
            setFullNameError(true);
            errors = true;
        }

        if (email === '') {
            setEmailError(true);
            errors = true;
        }

        if (phone === '') {
            setPhoneError(true);
            errors = true;
        }


        if (reason === '') {
            setReasonError(true);
            errors = true;
        }

        if (meeting.length === 0) {
            setMeetingError(true);
            errors = true;
        }


        if (errors) {
            return;
        }

        setRegisterStatus(STATUS.LOADING);

        const request = {
            contact: {
                fullName,
                email,
                phoneNumber: phone.replaceAll(" ", "")
            },
            reasonForAbsence: reason,
            meetingId: meeting.map(value => value.pid)
        } as RegisterAbsenceRequest

        registerAbsence(request)
            .then(resp => {
                if (resp.ok) {
                    setRegisterStatus(STATUS.SUCCESS);
                    return;
                }
                throw resp
            })
            .catch(err => {
                setRegisterStatus(STATUS.FAILED);
                console.log(err);
            })
    }

    const disableFields = registerStatus === STATUS.LOADING;
    const mobileForm = <>
        <TextField fullWidth
                   onChange={handleFullNameChange}
                   error={fullNameError}
                   value={fullName}
                   disabled={disableFields}
                   label="Full Name"
                   variant="standard"
                   type={"text"}/>

        <MarginBottomDiv unit={'1rem'}/>

        <TextField fullWidth
                   label="email"
                   variant="standard"
                   onChange={handleEmailChange}
                   error={emailError}
                   value={email}
                   disabled={disableFields}
                   type={"email"}/>

        <MarginBottomDiv unit={'1rem'}/>

        <MuiTelInput fullWidth
                     onChange={handlePhoneChange}
                     label="phone"
                     variant="standard"
                     error={phoneError}
                     value={phone}
                     disabled={disableFields}/>

        <MarginBottomDiv unit={'1rem'}/>
        <MeetingSelector setMeetings={handleMeetingChange} meetingError={meetingError}/>

        <MarginBottomDiv unit={'1rem'}/>
        <TextField fullWidth
                   onChange={handleReasonChange}
                   error={reasonError}
                   value={reason}
                   disabled={disableFields}
                   label="Reason for absence"
                   variant="standard"
                   rows={3}
                   multiline
                   type={"text"}/>
    </>;

    const webForm = <>

        <TextField sx={{width: '40%'}}
                   onChange={handleFullNameChange}
                   error={fullNameError}
                   value={fullName}
                   disabled={disableFields}
                   label="Full Name"
                   variant="outlined"
                   type={"text"}/>

        <MarginBottomDiv unit={'1rem'}/>
        <div style={{width: "40%", display: "flex", flexDirection: "row"}}>
            <div style={{width: '50%'}}>
                <TextField sx={{width: "97%"}}
                           label="email"
                           onChange={handleEmailChange}
                           error={emailError}
                           value={email}
                           disabled={disableFields}
                           variant="outlined"
                           type={"text"}/>
            </div>

            <div style={{width: '50%'}}>
                <MuiTelInput sx={{width: "100%"}}
                             label="phone"
                             variant="outlined"
                             onChange={handlePhoneChange}
                             error={phoneError}
                             value={phone}
                             disabled={disableFields}/>
            </div>
        </div>

        <MarginBottomDiv unit={'1rem'}/>
        <div style={{ width: '40%'}}>
            <MeetingSelector setMeetings={handleMeetingChange} meetingError={meetingError}/>
        </div>

        <MarginBottomDiv unit={'1rem'}/>
        <TextField sx={{width: '40%'}}
                   onChange={handleReasonChange}
                   error={reasonError}
                   value={reason}
                   disabled={disableFields}
                   label="Reason For Absence"
                   variant="outlined"
                   rows={3}
                   multiline
                   type={"text"}/>
    </>;

    return (
        <PageWrapper>
            <Paper elevation={0}>
                {
                    registerStatus === STATUS.SUCCESS ?
                        <Box sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <AbsenceSuccess/>
                            <div style={{marginTop: '2rem'}}/>

                            <ResponsiveStack spacing={2}>


                                <Button size={"large"} variant={"contained"} color={'primary'} onClick={() => navigate('/absences')}>
                                    my absences
                                </Button>

                                <Button size={"large"} variant={"contained"} color={'error'} onClick={reset}>
                                    close
                                </Button>
                            </ResponsiveStack>


                        </Box>
                        :
                        <Box
                            sx={{
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant={"h4"} component={"div"} color={"primary"}>
                                Register Absence <AppRegistration fontSize={"large"}/>
                            </Typography>

                            <div style={{marginTop: '2rem'}}/>


                            {
                                registerStatus === STATUS.FAILED ?
                                    <>
                                        <Alert severity={"error"} sx={{ width: matches ? '40%' : 'none'}}>
                                            {registerError}
                                        </Alert>

                                        <MarginBottomDiv unit={'2rem'}/>
                                    </>
                                    :
                                    null
                            }

                            {
                                matches ?
                                    webForm
                                    :
                                    mobileForm
                            }


                            <div style={{marginTop: '2rem'}}/>


                            <Button size={"large"} variant={"contained"} color={'primary'} onClick={registerUserAbsence} disabled={disableFields}>
                                submit
                            </Button>

                        </Box>
                }


            </Paper>

            <BackDrop open={disableFields} color={"primary"}/>
        </PageWrapper>
    )
}
export default AbsencePage;
